<template>
  <div>
    <section
      id="intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row>
          <b-col
            md="7"
            lg="6"
          >
            <h1 v-html="$t('hos.intro.title')"></h1>
            <h2 v-html="$t('hos.intro.subtitle')"></h2>

            <ul class="buttons">
              <li>
                <a
                  class="btn btn-lg btn-primary"
                  :href="`${$t('link.groupes')}/sympa/create_list_request`"
                  v-html="$t('hos.intro.btn-create')"
                ></a>
              </li>
              <li>
                <a
                  class="btn btn-lg btn-outline-warning"
                  :href="$t('hos.data.docs')"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play mr-1"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </a>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[1].$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('hos.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import ModalDocs from '../../../commons/components/ModalDocs.vue';

export default {
  components: {
    ModalDocs,
  },

  data() {
    const days = (((new Date('6/7/2023')).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24) + 0.5).toFixed(0);
    return {
      days,
      docs: false,
      s: days > 1 ? 's' : '',
    };
  },
};
</script>

<style lang="scss">
#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;
  z-index: 1;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../public/img/intro.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .col-md-7 {
    background: #ffffff99;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  h1 {
    color: var(--f-g9);
    font-size: 2.75rem;
    margin: 2rem 0 1.5rem;
    font-weight: 300;

    @media (min-width: 768px) {
      font-size: 3rem;
      margin: 3rem 0 2rem;
    }

    @media (min-width: 992px) {
      font-size: 4rem;
      margin: 4rem 0 3rem;
    }

    @media (min-width: 1200px) {
      font-size: 5rem;
    }

  }

  h2 {
    color: var(--f-g9);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;
    max-width: 80%;

    @media (max-width: 992px) {
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  #learn-more {
    label {
      color: var(--f-g9);
      background: none;
      text-decoration: none;

      i::before {
        color: var(--f-g9);
      }
    }

    span {
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

 .buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 4rem 0 5.5rem;
    padding: 0;
    list-style: none;
    max-width: 90%;

    @media (max-width: 768px) {
      margin: 3rem 0 1.5rem;
      flex-direction: column;
    }

    li {
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 12rem;
      margin: 0.25rem 0.75rem;

      @media (max-width: 768px) {
        margin: 0.125rem 0;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0.5rem 1rem;
      font-weight: bold;

      i.fas {
        margin-right: 0.25rem;
      }
    }
  }

  .btn-outline-warning {
    background: #fff;
    border-color: #fff;
    font-weight: bold;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      border-color: var(--f-o7);
      color: #fff;
    }
  }
}
</style>
