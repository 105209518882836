import { render, staticRenderFns } from "./What.vue?vue&type=template&id=3653f261"
import script from "./What.vue?vue&type=script&lang=js"
export * from "./What.vue?vue&type=script&lang=js"
import style0 from "./What.vue?vue&type=style&index=0&id=3653f261&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports