<template>
  <section
    id="h-what"
    ref="what"
  >
    <b-container class="px-md-0">
      <b-row>
        <b-col
          lg="7"
        >
          <web-screen :img="`${$t('/')}img/${$t('lang')}/screenshot.png`" />
        </b-col>
        <b-col
          class="px-3 pt-5"
          lg="5"
        >
          <h2
            class="sr-only"
            v-html="$t('hos.what.title')"
          ></h2>
          <div
            class="mt-3"
            v-html="$t('hos.what.md')"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    WebScreen,
  },

};
</script>

<style lang="scss">
#h-what {
  padding: 4rem 0;
}
</style>
